(function () {
    const EMAIL_ADDRESS_BASE_64 = "aGVsbG9Ac2x5ZXIubmV0"
    let emailAddress = atob(EMAIL_ADDRESS_BASE_64);

    setTimeout(() => {
        let emailElement = <HTMLAnchorElement>document.getElementById("email");
        emailElement.href = `mailto:${emailAddress}`;
        emailElement.text = emailAddress;
        emailElement.classList?.remove("hide");
    }, 200);

    console.log("Loaded.");
}());
